<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          @click.prevent="() => editItem()"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Edit</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click.prevent="() => removeItem(itemId)"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <EditCountry ref="editCountry" :id="itemId" :editType="editType" />
    <EditState ref="editState" :id="itemId" :editType="editType" />
    <EditCity ref="editCity" :id="itemId" :editType="editType" />
    <EditArea ref="editArea" :id="itemId" :editType="editType" />
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import EditCountry from "@/own/components/shipping/countries/Edit/EditCountry";
import EditState from "@/own/components/shipping/countries/Edit/EditState";
import EditCity from "@/own/components/shipping/countries/Edit/EditCity";
import EditArea from "@/own/components/shipping/countries/Edit/EditArea";
import {
  GET_AREAS,
  GET_CITIES,
  GET_COUNTRIES,
  GET_STATES,
} from "@/core/services/store/shipping_countries.module";

export default {
  name: "ActionsMenu",
  components: { EditArea, EditCity, EditState, EditCountry },
  props: ["itemId", "editType"],
  data: () => ({
    editCountry: false,
    editState: false,
    editCity: false,
    editArea: false,
  }),
  methods: {
    editItem() {
      if (this.editType === "country") this.$refs.editCountry.toggleModal();
      else if (this.editType === "state") this.$refs.editState.toggleModal();
      else if (this.editType === "city") this.$refs.editCity.toggleModal();
      else this.$refs.editArea.toggleModal();
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post("/shipping/countries/destroy", {
            id,
            type: this.editType,
          }).then(() => {
            Swal.fire("Deleted!", "Record has been deleted.", "success").then(
              (res) => {
                if (res.isConfirmed) {
                  if (this.editType === "country")
                    this.$store.dispatch(GET_COUNTRIES).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  else if (this.editType === "state") {
                    let payload = {
                      country_id:
                        this.$store.getters
                          .getSHIPPINGCOUNTRIESSelectedCountryId,
                      type: "state",
                    };
                    this.$store.dispatch(GET_STATES, payload).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  } else if (this.editType === "city") {
                    let payload = {
                      state_id:
                        this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId,
                      type: "city",
                    };
                    this.$store.dispatch(GET_CITIES, payload).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  } else {
                    let payload = {
                      city_id:
                        this.$store.getters.getSHIPPINGCOUNTRIESSelectedCityId,
                      type: "area",
                    };
                    this.$store.dispatch(GET_AREAS, payload).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  }
                }
              }
            );
          });
        }
      });
    },
  },
};
</script>
