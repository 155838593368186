import { render, staticRenderFns } from "./NestedDataTable.vue?vue&type=template&id=32993dd2&scoped=true"
import script from "./NestedDataTable.vue?vue&type=script&lang=js"
export * from "./NestedDataTable.vue?vue&type=script&lang=js"
import style0 from "./NestedDataTable.vue?vue&type=style&index=0&id=32993dd2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32993dd2",
  null
  
)

export default component.exports