<template>
  <div class="poppins d-flex flex-column custom-content-height">
    <div class="modal-header border-bottom-0">
      <h3 class="mb-0 font-weight-bolder">Tracking page</h3>
    </div>
    <div class="ma-auto h-50 w-100" v-if="error">
      <span
        class="d-flex align-center justify-center text-dark-50 poppins display5 text-center"
        >{{ error }}</span
      >
    </div>
    <div v-else class="modal-body d-flex flex-column mx-sm-10 pt-0 pb-6">
      <v-tabs v-model="tabs" mobile-breakpoint="300">
        <v-tab>
          <h3 class="font-weight-bolder ma-0 font-size-h6 poppins">Main</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-bolder ma-0 font-size-h6 poppins">
            Status
          </h3></v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat>
            <v-card-text class="px-0 mt-4">
              <div class="row pb-xl-16 ml-2 ml-sm-0">
                <div class="col-12 col-lg-6 pa-0 pa-sm-auto">
                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      ><span class="required">Logo</span></label
                    >
                    <div class="col-10 col-sm-9">
                      <v-file-input
                        v-model="logo"
                        accept="image/png, image/jpeg, image/bmp"
                        outlined
                        placeholder="Click to add Logo"
                        clearable
                        hide-details
                        dense
                      ></v-file-input>
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Phone</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="tel"
                        v-model="phone"
                        class="form-control h-40px"
                        placeholder="Phone"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Email</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="email"
                        v-model="email"
                        class="form-control h-40px"
                        placeholder="Email"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <div class="col-2 pa-0">
                      <v-icon class="ml-4">mdi-facebook</v-icon>
                    </div>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="facebook"
                        class="form-control h-40px"
                        placeholder="Facebook link"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <div class="col-2 pa-0">
                      <v-icon class="ml-4">mdi-linkedin</v-icon>
                    </div>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="linkedin"
                        class="form-control h-40px"
                        placeholder="LinkedIn link"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <div class="col-2 pa-0">
                      <v-icon class="ml-4">mdi-twitter</v-icon>
                    </div>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="twitter"
                        class="form-control h-40px"
                        placeholder="Twitter link"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <div class="col-2 pa-0">
                      <v-icon class="ml-4">mdi-instagram</v-icon>
                    </div>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="instagram"
                        class="form-control h-40px"
                        placeholder="Instagram link"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>
                </div>

                <div class="col-12 col-lg-6 mt-10 mt-lg-0 pa-0 pa-sm-auto">
                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      ><span>Image</span></label
                    >
                    <div class="col-10 col-sm-9">
                      <v-file-input
                        v-model="image"
                        accept="image/png, image/jpeg, image/bmp"
                        outlined
                        placeholder="Click to add Image"
                        clearable
                        hide-details
                        dense
                      ></v-file-input>
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>
                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Gradient 1</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="color_1"
                        class="form-control h-40px"
                        placeholder="Click to add color"
                        @click="addColor1"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Gradient 2</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="color_2"
                        class="form-control h-40px"
                        placeholder="Click to add color"
                        @click="addColor2"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Background color</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="color_3"
                        class="form-control h-40px"
                        placeholder="Click to add color"
                        @click="addColor3"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Text Color 1</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="color_4"
                        class="form-control h-40px"
                        placeholder="Click to add color"
                        @click="addColor4"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>

                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Text Color 2</span>
                    </label>
                    <div class="col-10 col-sm-9">
                      <input
                        type="text"
                        v-model="color_5"
                        class="form-control h-40px"
                        placeholder="Click to add color"
                        @click="addColor5"
                      />
                    </div>
                    <div class="offset-sm-1"></div>
                  </div>
                </div>
              </div>
              <v-dialog
                v-model="dialog"
                persistent
                transition="dialog-bottom-transition"
                max-width="500"
              >
                <div
                  class="bg-white h-400px d-flex flex-column align-center justify-space-around"
                >
                  <v-color-picker
                    v-model="picker"
                    elevation="1"
                    dot-size="16"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"
                    :width="colorPickerWidth"
                    class="poppins mx-auto"
                    ref="colorPicker"
                  ></v-color-picker>
                  <div class="d-flex align-center justify-center">
                    <button
                      type="reset"
                      @click="dialog = false"
                      class="btn btn-sm btn-light mr-3 px-5 py-3 ls1"
                    >
                      Cancel
                    </button>
                    <button
                      @click="addColors"
                      class="btn btn-sm btn-info px-5 py-3 ls1"
                    >
                      Add Color
                    </button>
                  </div>
                </div>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text class="px-0 mt-4">
              <div class="row px-4" v-for="status in statuses" :key="status.id">
                <div class="col-12 col-lg-4">
                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Title</span>
                    </label>
                    <div class="col-10">
                      <input
                        type="text"
                        v-model="status.title"
                        class="form-control h-40px"
                        placeholder="Order received"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-8 mt-6 mt-lg-0">
                  <div class="form-group row d-flex align-center">
                    <label
                      class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                    >
                      <span class="required">Description</span>
                    </label>
                    <div class="col-10">
                      <input
                        type="text"
                        v-model="status.text"
                        class="form-control h-40px"
                        placeholder="Domain Prod"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <div class="h-75px d-flex align-center justify-center">
        <button
          type="reset"
          @click="handleClear"
          class="btn btn-light mr-3 px-5 py-3 ls1"
        >
          Clear
        </button>
        <button
          type="submit"
          @click="handleSubmit"
          class="btn btn-info px-5 py-3 ls1"
        >
          Submit
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Modal body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "TrackingPageClient",
  components: {},
  data: function () {
    return {
      dialog: false,
      logo: null,
      image: null,
      phone: null,
      email: null,
      picker: {
        hex: "#FF00FF",
        hexa: "#FF00FFFF",
      },
      facebook: null,
      linkedin: null,
      twitter: null,
      instagram: null,
      color_1: null,
      color_2: null,
      color_3: null,
      color_4: null,
      color_5: null,
      colorIndex: null,
      error: null,
      tabs: null,
      statuses: [],
    };
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    ApiService.post("/shipping/trackingPage/edit")
      .then(({ data }) => {
        this.color_1 = data.page.colors[0];
        this.color_2 = data.page.colors[1];
        this.color_3 = data.page.colors[2];
        this.color_4 = data.page.colors[3];
        this.color_5 = data.page.colors[4];
        this.logo = data.page.logo;
        this.image = data.page.image;
        this.phone = data.page.phone;
        this.email = data.page.email;
        this.facebook = data.page.social_media.facebook;
        this.linkedin = data.page.social_media.linkedin;
        this.twitter = data.page.social_media.twitter;
        this.instagram = data.page.social_media.instagram;
        this.statuses = data.page.statuses;
        this.$store.commit(SET_PAGE_LOADING, false);
      })
      .catch((error) => {
        this.error = error.response.data?.message;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
  },
  methods: {
    addColors() {
      if (this.colorIndex === 1) this.color_1 = this.picker.hex;
      else if (this.colorIndex === 2) this.color_2 = this.picker.hex;
      else if (this.colorIndex === 3) this.color_3 = this.picker.hex;
      else if (this.colorIndex === 4) this.color_4 = this.picker.hex;
      else this.color_5 = this.picker.hex;
      this.dialog = false;
    },
    addColor1() {
      this.dialog = true;
      this.colorIndex = 1;
    },
    addColor2() {
      this.dialog = true;
      this.colorIndex = 2;
    },
    addColor3() {
      this.dialog = true;
      this.colorIndex = 3;
    },
    addColor4() {
      this.dialog = true;
      this.colorIndex = 4;
    },
    addColor5() {
      this.dialog = true;
      this.colorIndex = 5;
    },
    async handleSubmit() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let formData = new FormData();
      formData.append("logo", this.logo);
      formData.append("image", this.image);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("color_1", this.color_1);
      formData.append("color_2", this.color_2);
      formData.append("color_3", this.color_3);
      formData.append("color_4", this.color_4);
      formData.append("color_5", this.color_5);
      this.facebook && formData.append("facebook", this.facebook);
      this.linkedin && formData.append("linkedin", this.linkedin);
      this.twitter && formData.append("twitter", this.twitter);
      this.instagram && formData.append("instagram", this.instagram);
      formData.append("statuses", JSON.stringify(this.statuses));
      await ApiService.post(
        `${process.env.VUE_APP_BASE_URL}/shipping/trackingPage/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then(() => {
        Swal.fire({
          title: "Updated",
          text: `The Tracking Page data has been Updated`,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    handleClear() {
      this.logo = null;
      this.image = null;
      this.phone = null;
      this.email = null;
      this.picker = null;
      this.facebook = null;
      this.linkedin = null;
      this.twitter = null;
      this.instagram = null;
      this.color_1 = null;
      this.color_2 = null;
      this.color_3 = null;
      this.color_4 = null;
      this.color_5 = null;
      this.colorIndex = null;
    },
  },
  computed: {
    colorPickerWidth: function () {
      let width = 250;
      if (this.$vuetify.breakpoint.xsOnly)
        width = this.$vuetify.breakpoint.width - 80;
      else if (this.$vuetify.breakpoint.smAndUp) width = 450;
      return width;
    },
  },
};
</script>
